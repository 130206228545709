<template>
  <div>
    <vue-contract-version />
  </div>
</template>
<script>
import contractVersion from '@/components/contractVersion/Index.vue'

export default {
  components: {
    'vue-contract-version': contractVersion,
  },
}
</script>
