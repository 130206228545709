<template>
  <div>
    <b-col cols="12">
      <good-table
        :columns="columns"
        :rows="rows"
        :select-row="true"
        title-table="Versiones de contratos"
        :is_active_create="$can('create', 'contrato')"
        :is_active_edit="$can('update', 'contrato')"
        :is-loading="isLoading"
        @Crear="Crear"
        @Edit_one="Edit_one"
        @edit="Edit"
        @selection-changed="selectionChanged"
      />
    </b-col>
    <vue-form
      :modal-show="modalShow"
      :info-data="infoData"
      @create-result="getContractVersion"
      @edit-result="getContractVersion"
      @close="close"
    />
  </div>
</template>

<script>
import {
  BCol,
} from 'bootstrap-vue'
import GoodTable from '@/components/table/Index.vue'
import Form from '@/components/contractVersion/form.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BCol,
    GoodTable,
    'vue-form': Form,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      selectRow: null,
      selecction: [],
      modalShow: false,
      infoData: {},
      columns: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Nombre',
          field: 'name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Descripción',
          field: 'description',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Creado',
          field: 'created_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Modificado',
          field: 'updated_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
      ],
    }
  },
  created() {
    this.getContractVersion()
  },
  methods: {
    close() {
      this.modalShow = false
      this.infoData = {}
    },
    formatFn(value) {
      if (!value) return ''
      const dateFormat = value
      return moment(dateFormat).format('YYYY-MM-DD HH:mm:ss')
    },
    getContractVersion() {
      this.isLoading = true
      this.$http.get('/api/v1/contract-version')
        .then(res => {
          this.isLoading = false
          this.rows = res.data
        })
    },
    Crear() {
      this.modalShow = true
    },
    Edit(val) {
      this.infoData = val
      this.modalShow = true
    },
    Edit_one() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShow = true
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
